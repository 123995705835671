<template lang="pug">
div.panel-header
  div.header.text-center
    h2.title Polling as a Service
    p.category
      div Create anonymous polls on any contract to receive feedback.
</template>

<script>
export default {
  props: {},
};
</script>

<style scoped>
.category {
  text-transform: inherit;
}
</style>
