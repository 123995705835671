export default {
  trustedTimestamping: {
    cost: null,
    hashes: [],

    xlm: {
      xlmPublicKey: "GACH6YMYFZ574FSGCV7IJXTGETEQL3DLQK64Z6DFGD57PZL5RH6LYOJT",
      xlmSecretSeed: localStorage.xlmSeed || null,
      usdToSend: "1.00",
    },
  },
};
