export default {
  asaas: {
    createSwapCost: null,
    swapCost: null,
    instanceGasCost: {},
    instanceServiceCost: {},
    gas: null,
    swaps: [],
  },
};
