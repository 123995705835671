<template lang="pug">
div.panel-header
  div.header.text-center
    h2.title Decentralized Tax Reporting (DTAX)
    p.category
      | Provide tax documentation for all transactions from a given wallet address.
</template>

<script>
export default {};
</script>

<style scoped>
.category {
  text-transform: inherit;
}
</style>
